import React, { useEffect, useState } from 'react';
import 'jquery-ui/ui/widgets/tooltip';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/tooltip.css';
import Catalog from '../components/Catalog';
import config from '../config';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

const Currencies = ({ me = false }) => {
  const [currencies, setCurrencies] = useState([]);
  const location = useLocation();
  const currentUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

  useEffect(() => {
    fetchShopItems();
  }, []);

  const fetchShopItems = () => {
    const url = `${config.serverURL}/shopItems`;

    fetch(url)
    .then(res => res.json())
    .then(data => {
      setCurrencies(data.premiumCurrency);
    })
    .catch(error => {
      console.error('Failed to fetch shop items:', error);
    });
  };

  const CONTENT_KEYS = 
  {             
     imgKey: "preview",
     cardTitleKey: "full_name",
     cardDescriptionKey: "description",
     priceKey: "price",
     discountedPriceKey: "discounted"
  };

  return (
    <div className='table__container'>
      <Helmet>
        <title>Buy Emeralds</title>
        <meta name="description" content="Buy Emeralds." />
        <meta property="og:title" content="Buy Emeralds" />
        <meta property="og:description" content="Buy Emeralds" />
        <meta property="og:url" content={currentUrl} />
        <meta property="twitter:title" content="Buy Emeralds" />
        <meta property="twitter:description" content="Buy Emeralds" />
        <meta property="twitter:url" content={currentUrl} />
      </Helmet>
      <Catalog
         data = {currencies}    
         contentKeys={CONTENT_KEYS}  
         skeleton={0}
         cardSize="md"
         btnOneText="Purchase"
         kind={2}
      />
    </div>
  );
}

export default Currencies;