import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './../Catalog.css';
import { formatNumber, showNotification } from './Utils';
import premiumCurrencyImage from '../images/premiumCurrency.png';
import euroCurrencyImage from '../images/euro.png';
import ConfirmationModal from './ConfirmationModal';
import ActionButton from './ActionButton';
import PLACEHOLDER from './../images/placeholder.svg';
import config from '../config';
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

const CARD_CLASS = {
  sm: 'rcv-catalog-card rcv-catalog-card-custom rcv-card-sm rcv-catalog-card-custom',
  md: 'rcv-catalog-card rcv-catalog-card-custom rcv-card-md rcv-catalog-card-custom',
  lg: 'rcv-catalog-card rcv-catalog-card-custom rcv-card-lg rcv-catalog-card-custom'
};

const OBJECT_CLASS = {
  sm: 'rcv-object rcv-object-sm rcv-object-sm-custom',
  md: 'rcv-object rcv-object-md rcv-object-md-custom',
  lg: 'rcv-object rcv-object-lg rcv-object-lg-custom'
};

const Catalog = ({ data, contentKeys, cardSize: initialCardSize = 'md', skeleton = 0, btnOneText = '', btnTwoText = '', btnOneStyle = {}, btnTwoStyle = {}, kind = 1, btnTwoHandler }) => {
  const initialState = {
    productArray: Array.isArray(data) ? data : [],
    contentKeys: contentKeys || {},
    cardSize: typeof initialCardSize === 'string' ? initialCardSize : 'md',
    skeletonCards: isNaN(skeleton) ? 0 : skeleton,
    btnOneText: typeof btnOneText === 'string' ? btnOneText : '',
    btnTwoText: typeof btnTwoText === 'string' ? btnTwoText : '',
    btnOneStyle: btnOneStyle || {},
    btnTwoStyle: btnTwoStyle || {},
    isModalOpen: false,
    modalProduct: null,
    purchaseAmount: 1,
    modalDescription: '',
    kind: kind
  };

  const [state, setState] = useState(initialState);
  const [{ isPending, isRejected }] = usePayPalScriptReducer();

  useEffect(() => {
    setState(prevState => ({
      ...prevState,
      productArray: Array.isArray(data) ? data : []
    }));
  }, [data]);

  const onCreateOrder = (product) => (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          description: product.name,
          amount: {
            currency_code: "EUR",
            value: product.price,
            breakdown: {
              item_total: {
                currency_code: "EUR",
                value: product.price
              }
            }
          },
          items: [
            {
              name: product.name,
              sku: "001",
              unit_amount: {
                currency_code: "EUR",
                value: product.price
              },
              quantity: "1"
            }
          ]
        }
      ],
    });
  };

  async function successOrder(product, details) {
    const url = `${config.serverURL}/success`;
    const twitchData = JSON.parse(localStorage.getItem('twitchData')); // Corrected key name

    if (!twitchData) {
      showNotification('Twitch data not found. Please log in again.');
      return;
    }

    const params = new URLSearchParams({
      payerId: details.payer.payer_id,
      paymentId: details.id,
      twitchID: twitchData.id,
      itemName: product.name,
      amount: 1
    });

    try {
      const response = await fetch(`${url}?${params.toString()}`, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      });

      if (response.ok) {
        showNotification(`You successfully bought ${product.full_name} for EUR${formatNumber(product.price)}`, () => {
          window.location.reload();
        });
      } else {
        const errorResponse = await response.json();
        if (response.status === 401) {
          showNotification('You need to login.', () => {
            navigate(`/`);
          });
        } else if (response.status === 404 || response.status === 403) {
          throw new Error(errorResponse.message);
        } else {
          throw new Error('Error confirming order, please try again.');
        }
      }
    } catch (error) {
      showNotification(error.message);
    }
  }

  const onApproveOrder = (product) => (data, actions) => {
    return actions.order.capture().then((details) => {
      console.log(details);
      console.log(JSON.stringify(details));
      successOrder(product, details);
    });
  };

  const navigate = useNavigate();

  const updateModalDescription = (amount) => {
    setState(prevState => ({
      ...prevState,
      modalDescription: state.kind === 2 
        ? `Confirm buying ${amount > 1 ? `${amount} ${state.modalProduct.title}` : state.modalProduct.title} for EUR${(state.modalProduct.price * amount).toFixed(2)}?`
        : `Confirm buying ${amount > 1 ? `${amount} ${state.modalProduct.title}` : state.modalProduct.title} for ${formatNumber(state.modalProduct.price * amount)} Emeralds?`
    }));
  };

  const openModal = (product) => {
    setState(prevState => ({
      ...prevState,
      isModalOpen: true,
      modalProduct: product,
      purchaseAmount: product.amount ?? 1,
      modalDescription: state.kind === 2 
        ? `Confirm buying ${product.title} for EUR${product.price.toFixed(2)}?`
        : `Confirm buying ${product.title} for ${formatNumber(product.price)} Emeralds?`
    }));
  };

  const closeModal = () => {
    setState(prevState => ({
      ...prevState,
      isModalOpen: false
    }));
  };

  const confirmPurchase = async (amount = 1) => {
    const { modalProduct } = state;
    const url = `${config.serverURL}/buyPremiumItem`;
    const accessToken = localStorage.getItem('twitchToken');

    const payload = {
      jwt: null,
      accessToken: accessToken,
      name: modalProduct.name,
      amount: amount
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });

      if (response.ok) {
        showNotification(`You successfully bought ${amount} ${modalProduct.name} for ${state.kind === 2 ? 'EUR' : ''}${formatNumber(modalProduct.price * amount)}`, () => {
          window.location.reload();
        });        
      } else {
        if (response.status === 401) {
          showNotification('You need to login before purchasing.', () => {
            navigate(`/`);
          });
        } else if (response.status === 404 || response.status === 403) {
          const errorResponse = await response.json();
          throw new Error(errorResponse.message);
        } else {
          throw new Error('Error purchasing item, please try again.');
        }
      }
    } catch (error) {
      showNotification(error.message);
    }
    closeModal();
  };

  const GenerateProductCard = (product, index) => (
    <div className={CARD_CLASS[state.cardSize]} key={index}>
      <div className="rcv-card-content">
        <div className="rcv-product-image-container rcv-product-image-container-custom">
          <div className="image-border rpgui-container framed-golden">
            <img
              src={product[state.contentKeys.imgKey] || PLACEHOLDER}
              onError={(e) => { e.target.src = PLACEHOLDER; }}
              alt={`product-${index}`}
            />
          </div>
        </div>
        <div className="rcv-card-text">
          <div className="rcv-product-text rcv-product-text-custom">
            <h4 className="rcv-product-name-custom">
              {product[state.contentKeys.cardTitleKey]}
            </h4>
            <div className="rcv-product-description rcv-product-description-custom">
              {product[state.contentKeys.cardDescriptionKey]}
            </div>
          </div>
          <div className="rcv-product-price">
            <div className="premium-currency-content">
              <img
                className={state.kind === 2 ? "euro-image" : "small-image"}
                src={state.kind === 2 ? euroCurrencyImage : premiumCurrencyImage}
                alt={state.kind === 2 ? "euro currency" : "premium currency"}
              />
              <p>{formatNumber(product[state.contentKeys.priceKey])}</p>
            </div>
          </div>
          <div className="rcv-product-buttons">
            {state.kind === 2 ? (
              <>
                {isPending && <div>Loading PayPal Buttons...</div>}
                {isRejected && <div>Failed to load PayPal Buttons. Please try again.</div>}
                {!isPending && !isRejected && (
                  <PayPalButtons 
                    style={{ layout: "vertical", shape: "rect" }}
                    fundingSource="paypal"
                    createOrder={onCreateOrder(product)}
                    onApprove={onApproveOrder(product)}
                  />
                )}
              </>
            ) : (
              <ActionButton product={product} amount={product.amount ?? 1} onBuy={() => openModal(product)} />
            )}
            {state.btnTwoText &&
              <button
                style={state.btnTwoStyle}
                className="rpgui-button"
                onClick={() => btnTwoHandler(product)}
              >
                {state.btnTwoText}
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  );

  const GenerateCatalogView = () => (
    <div className="rcv-container">
      {state.productArray.length > 0 ? state.productArray.map((product, index) => (
        <div style={{ position: 'relative' }} className={`${OBJECT_CLASS[state.cardSize]} rpgui-container framed-golden`} key={index}>
          {GenerateProductCard(product, index)}
        </div>
      )) : <div>No products available</div>}
    </div>
  );

  return (
    <>
      {GenerateCatalogView()}
      <ConfirmationModal
        isOpen={state.isModalOpen}
        title="Confirm Purchase"
        description={state.modalDescription}
        onConfirm={() => confirmPurchase(state.purchaseAmount)}
        onClose={closeModal}
        totalAmount={state.purchaseAmount}
        updateDescription={updateModalDescription}
      />
    </>
  );
};

Catalog.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  contentKeys: PropTypes.object.isRequired,
  cardSize: PropTypes.string,
  skeleton: PropTypes.number,
  btnOneText: PropTypes.string,
  btnTwoText: PropTypes.string,
  btnOneHandler: PropTypes.func,
  btnTwoHandler: PropTypes.func,
  cardControls: PropTypes.func,
  imageClickHandler: PropTypes.func,
  kind: PropTypes.number,
};

export default Catalog;