import Home from "./pages/Home";
import Product from "./pages/Product";
import Products from "./pages/Products";
import Currencies from "./pages/Currencies";
import History from "./pages/History";
import { ImageCacheProvider } from "./components/ImageCacheContext";
import RedirectToHome from "./components/RedirectToHome";

const routesConfig = [
  { path: "/", element: <Home />, subtitle: "Welcome to the Shop" },
  { 
    path: "/product/:productId", 
    element: (
      <ImageCacheProvider>
        <Product />
      </ImageCacheProvider>
    ), 
    subtitle: "View Product Details" 
  },
  { 
    path: "/products", 
    element: (
      <ImageCacheProvider>
        <Products />
      </ImageCacheProvider>
    ), 
    subtitle: "Browse All Products" 
  },
  { 
    path: "/emeralds", 
    element: <Currencies />,
    subtitle: "Buy Emeralds" 
  },
  { 
    path: "/history", 
    element: (
      <ImageCacheProvider>
        <History />
      </ImageCacheProvider>
    ), 
    subtitle: "Purchase History" 
  },
  // Wildcard route for non-existent paths
  { 
    path: "*", 
    element: <RedirectToHome /> 
  },
];

export default routesConfig;