import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, matchPath } from "react-router-dom";
import Nav from "./components/Nav";
import routesConfig from "./routesConfig";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-responsive-pagination/themes/classic.css';
import { ToastProvider, useToast } from './components/ToastContext';
import useAuth from './hooks/useAuth'; // Import the custom hook
import { isMobile, isTablet } from 'react-device-detect';
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const initialOptions = {
  "client-id": process.env.NODE_ENV === "development" ? "AZZx_dwcJVB58YpQ8qulqTTL3_SXLSuRjfyf6KZ0ztmFwJlL5dLJ3i29V72LoLLgTMdEjybrAskBUmBK" : "AS-KHfIdrrVfM0t5I7-Omd4Z4CcK4cHAi9U_KdG7wuNM3gx8i-iDK4D7kGOojzKaKu7JVSpl232D2vhV",
  currency: "EUR",
  intent: "capture",
};

function AppContent() {
  useAuth(); // Use the custom hook to check authentication

  const location = useLocation();
  const { toastMessage, setToastMessage } = useToast();
  const [shouldShowToast, setShouldShowToast] = useState(false);

  const env = process.env.NODE_ENV;
  const appTitle = env === 'development' ? "OSRPG Test Shop" : "OSRPG Shop";
  const navTitle = env === 'development' ? "OneStreamRPG Test Shop" : "OneStreamRPG Shop";

  useEffect(() => {
    document.title = appTitle;
  }, [appTitle]);

  useEffect(() => {
    const toastPosition = isMobile || isTablet ? "bottom-center" : "bottom-right";
    if (shouldShowToast && toastMessage) {
      toast(toastMessage, {
        position: toastPosition,
      });
      setToastMessage(null); // Clear the message after showing the toast
      setShouldShowToast(false); // Reset the flag
    }
  }, [shouldShowToast, toastMessage, setToastMessage]);

  useEffect(() => {
    if (toastMessage) {
      setShouldShowToast(true);
    }
  }, [toastMessage, location]);

  const currentRoute = routesConfig.find(route =>
    matchPath(route.path, location.pathname)
  );

  const subtitle = currentRoute ? currentRoute.subtitle : "Official OSRPG Shop";

  return (
    <div className="container">
      <div className="inner rpgui-container framed" style={{ position: 'relative' }}>
        <Nav title={navTitle} subtitle={subtitle} />
        <Routes>
          {routesConfig.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Routes>
      </div>
      <ToastContainer 
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        toastClassName="rpgui-container framed-golden-2 notification"
      />
    </div>
  );
}

function App() {
  return (
    <PayPalScriptProvider options={initialOptions}>
      <ToastProvider>
        <AppContent />
      </ToastProvider>
    </PayPalScriptProvider>
  );
}

export default App;