import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { formatNumber } from './Utils';
import premiumCurrencyImage from '../images/premiumCurrency.png';
import { useMediaQuery } from 'react-responsive';
import $ from 'jquery';
import config from '../config';

const Nav = ({ title, subtitle }) => {
  const [premiumCurrency, setPremiumCurrency] = useState(0);
  const dropdownRef = useRef(null);
  const location = useLocation();
  const twitchData = localStorage.getItem("twitchData");
  const displayName = twitchData ? JSON.parse(twitchData).display_name : null;
  const isMobile = useMediaQuery({ maxWidth: 768 });

  useEffect(() => {
    const fetchMainUser = async () => {
      const accessToken = localStorage.getItem("twitchToken");
      if (!accessToken) {
        return;
      }

      try {
        const response = await fetch(`${config.serverURL}/getMainUser`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            accessToken: accessToken,
          }),
        });

        if (!response.ok) {
          throw new Error('Failed to fetch Premium Currency');
        }

        const data = await response.json();
        setPremiumCurrency(data.mainUser.premiumCurrency);
      } catch (error) {
        console.error('Error fetching Premium Currency:', error);
      }
    };

    fetchMainUser();
    const intervalId = setInterval(fetchMainUser, 30000);

    return () => clearInterval(intervalId);
  }, []);

  const handleLogout = (event) => {
    event.preventDefault();
    localStorage.removeItem("twitchToken");
    localStorage.removeItem("twitchData");
    localStorage.removeItem("characterId");
    window.location.href = "/";
  };

  const navItems = useMemo(() => [
    { name: 'Home', path: '/' },
    { name: 'Products', path: '/products' },
    { name: 'Emeralds', path: '/emeralds' },
    { name: 'History', path: '/history' },
  ], []);

  const handleSelectChange = (event) => {
    const selectedItem = navItems.find(item => item.path === event.target.value);
    if (selectedItem && selectedItem.onClick) {
      selectedItem.onClick(event);
    } else {
      window.location.href = event.target.value;
    }
  };

  useEffect(() => {
    const handleCustomDropdownClick = (event) => {
      const dropdownItems = $('.rpgui-dropdown-imp li').toArray();
      const clickedItemIndex = dropdownItems.indexOf(event.target);
      if (clickedItemIndex !== -1) {
        const selectedItem = navItems[clickedItemIndex];
        if (selectedItem && selectedItem.onClick) {
          selectedItem.onClick(event);
        } else {
          window.location.href = selectedItem.path;
        }
      }
    };

    $(document).on('click', '.osrpg-nav .rpgui-dropdown-imp li', handleCustomDropdownClick);

    return () => {
      $(document).off('click', '.osrpg-nav .rpgui-dropdown-imp li', handleCustomDropdownClick);
    };
  }, [navItems]);

  useEffect(() => {
    if (isMobile && dropdownRef.current && !dropdownRef.current.initialized) {
      dropdownRef.current.initialized = true;
      if (window.RPGUI) {
        window.RPGUI.create(dropdownRef.current, "dropdown");
        dropdownRef.current.className = '';
      }
    }
  }, [isMobile]);

  const isLoggedIn = localStorage.getItem("twitchToken") && localStorage.getItem("twitchData") && localStorage.getItem("characterId");
  
  if (isLoggedIn) {
    navItems.push({ name: 'Logout', path: '/logout', onClick: handleLogout });
  }

  return (
    <header className="osrpg-header">
      <h1>
        <a href="/">{title}</a>
      </h1>
      <div className={`header-content ${isMobile ? 'mobile' : ''}`}>
        <nav className="osrpg-nav">
          {isMobile ? (
            <div style={{ display: isMobile ? 'block' : 'none' }}>
              <select onChange={handleSelectChange} ref={dropdownRef}>
                {navItems.map(item => (
                  <option key={item.name} value={item.path}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>
          ) : (
            <ul className="nav-list">
              {navItems.map(item => (
                <li key={item.name}>
                  <a
                    href={item.path}
                    onClick={item.onClick}
                    className={location.pathname === item.path ? 'active' : ''}
                  >
                    {item.name}
                  </a>
                </li>
              ))}
            </ul>
          )}
        </nav>
        {displayName && (
          <div className="user-info">
            <div className="welcome">
              <p>Welcome, {displayName}!</p>
              <div className="premium-currency-content">
                <img className="small-image" src={premiumCurrencyImage} alt="premium currency"/>
                <p>{formatNumber(premiumCurrency)}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <hr className="golden" />
      <br />
      <h2 className="subtitle">{subtitle}</h2>
    </header>
  );
};

export default Nav;