import React, { useEffect, useState } from 'react';
import { formatDate } from '../components/Utils';
import 'jquery-ui/ui/widgets/tooltip';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/tooltip.css';
import ResponsivePagination from 'react-responsive-pagination';
import { dropEllipsis } from 'react-responsive-pagination/narrowBehaviour';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import config from '../config';

const History = ({ me = false }) => {
  const [history, setHistory] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    const fetchHistory = () => {
      setLoading(true);
      const url = `${config.serverURL}/premiumItemsLog`;

      const body = {
        page: currentPage,
        limit: itemsPerPage,
        accessToken: localStorage.getItem('twitchToken')
      };

      fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
      })
        .then(res => res.json())
        .then(data => {
          setHistory(data.logs);
          setTotalPages(data.totalPages);
          setLoading(false);
        })
        .catch(error => {
          console.error('Failed to fetch listings:', error);
          setLoading(false);
        });
    };

    fetchHistory();
  }, [currentPage, itemsPerPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const formatNumber = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <div>
      <div className='table__container'>
        <div className="rpgui-container framed-golden listing-table-container">
          <Table>
            <Thead>
              <Tr>
                <Th>Title</Th>
                <Th>Amount</Th>
                <Th>Date</Th>
                <Th>Currency</Th>
                <Th>Price</Th>
              </Tr>
            </Thead>
            <Tbody>
              {loading ? <Tr><Td colSpan="7">Loading...</Td></Tr> : history.map(hist => (
                <Tr key={hist._id}>
                  <Td><div className="osrpg-responsive-content">{hist.action === "buyCurrency" ? hist.item.name : hist.item.title}</div></Td>
                  <Td><div className="osrpg-responsive-content">{hist.amount}</div></Td>
                  <Td><div className="osrpg-responsive-content">{formatDate(hist.timestamp)}</div></Td>
                  <Td><div className="osrpg-responsive-content">{hist.action === "buyCurrency" ? "EUR" : "Emeralds"}</div></Td>
                  <Td><div className="osrpg-responsive-content">{formatNumber(hist.item.price*hist.amount)}</div></Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </div>
        <ResponsivePagination
          total={totalPages}
          current={currentPage}
          onPageChange={handlePageChange}
          narrowBehaviour={dropEllipsis}
          pageLinkClassName="osrpg-page-link"
        />
      </div>
    </div>
  );
}

export default History;