import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import 'jquery-ui/ui/widgets/tooltip';
import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/tooltip.css';
import Catalog from '../components/Catalog';
import config from '../config';
import { Helmet } from 'react-helmet';

const Products = ({ me = false }) => {
  const [products, setProducts] = useState([]);
  const location = useLocation();
  const currentUrl = `${window.location.origin}${location.pathname}${location.search}${location.hash}`;

  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = () => {
    const url = `${config.serverURL}/premiumItems`;

    const body = {
      accessToken: localStorage.getItem('twitchToken')
    };

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then(res => res.json())
      .then(data => {
        setProducts(data);
      })
      .catch(error => {
        console.error('Failed to fetch products:', error);
      });
  };

  const CONTENT_KEYS = 
  {             
     imgKey: "image",
     cardTitleKey: "title",
     cardDescriptionKey: "description",
     priceKey: "price",
     discountedPriceKey: "discounted"
  };

  return (
    <div className='table__container'>
      <Helmet>
        <title>Browse All Products</title>
        <meta name="description" content="Browse All Products." />
        <meta property="og:title" content="Browse All Products" />
        <meta property="og:description" content="Browse All Products" />
        <meta property="og:url" content={currentUrl} />
        <meta property="twitter:title" content="Browse All Products" />
        <meta property="twitter:description" content="Browse All Products" />
        <meta property="twitter:url" content={currentUrl} />
      </Helmet> 
      <Catalog
         data = {products}    
         contentKeys={CONTENT_KEYS}  
         skeleton={0}
         cardSize="md"
         btnOneText="Purchase"
      />
    </div>
  );
}

export default Products;