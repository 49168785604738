import React from 'react';

const ActionButton = ({ product, amount = 1, onBuy }) => {
    return (
        <button className={`buyButton rpgui-button`} onClick={() => onBuy(product, amount)}>
          <p>Purchase</p>
        </button>
    );
};

export default ActionButton;