import React, { useState, useEffect } from 'react';

const ConfirmationModal = ({ isOpen, title, description, onClose, onConfirm, totalAmount, updateDescription }) => {
  const [amount, setAmount] = useState(1);

  useEffect(() => {
    setAmount(1);
  }, [isOpen]);

  const handleAmountChange = (e) => {
    const newAmount = e.target.value;
    if (newAmount > 0 && newAmount <= totalAmount){
      setAmount(newAmount);
      updateDescription(newAmount);
    } else {
      setAmount(1);
      updateDescription(1);
    }    
  };

  const handleConfirmClick = () => {
    onConfirm(amount);
  };

  if (!isOpen) return null;

  return (
    <div className="confirmation-modal rpgui-container framed-golden-2 confirmation-modal">
      <h2>{title}</h2>
      <p>{description}</p>
      {totalAmount > 1 && (
        <div style={{display: 'flex', justifyContent: 'center'}}>
          <label style={{ display: 'inline-block', textAlign: 'center' }}>
            Amount:
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <input type="number" className="custom-amount" value={amount} onChange={handleAmountChange} min="1" />
            </div>
          </label>
        </div>
      )}
      <div className="confirmation-buttons">
        <button className="rpgui-button" onClick={handleConfirmClick} style={{ marginRight: '10px' }}><p>Yes</p></button>
        <button className="rpgui-button" onClick={onClose}><p>No</p></button>
      </div>
    </div>
  );
}

export default ConfirmationModal;